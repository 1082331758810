import React from "react"
import { theme } from '../styles/theme';
import { ThemeProvider } from '../styles/ThemeProvider';

import styled from 'styled-components';

import loadable from '@loadable/component';
const BackHomeBtn = loadable(() => import('../components/Button/backHomeBtn'))
const Head = loadable(() => import('../components/Head'))
const Layout = loadable(() => import('../components/Layout'))



const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.colors.nero};
  min-height: 100vh;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;

  .error-title {
    font-size: 15rem;
  }

  .error-message {
    font-size: 3rem;
    margin-bottom: 5rem;
  }
  /* background-image: url(fluid={data.errorBannerImage.childImageSharp.fluid); */
  /* background-image: url('../images/error-page.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #efefef;
  min-height: 100vh; */
  /* .BlogImage {
    min-height: 200vh;
  } */
`;

const ErrorPage = () => {    
   
  return (
      <ThemeProvider theme={theme}>
      <Layout>
        <Head title="Page Not Found" />
        <ErrorContent home>          
          <span className="error-title">404</span>
                  <h3 className="error-message">Sorry, the page you tried cannot be found</h3>
                  <BackHomeBtn />
        </ErrorContent>
          </Layout >
      </ThemeProvider>
  );
};

export default ErrorPage;
